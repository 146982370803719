import { useState } from "react";

export const IDRFormat = (num) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

export const MaskPhoneNumber = ({ item }) => {
  const [isShowMasking, setIsShowMasking] = useState({
    phone: {
      shown: false,
      id: '' 
    },
  });

  const maskPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/(\d{3})\d+(\d{4})/, '$1****$2');
    if(phoneNumber.includes('(')) {
      return phoneNumber.replace(/\(\d{3}\)\s\d{3,4}\s\d{2,4}/, (match) => {
        const areaCode = match.match(/\(\d{3}\)/)[0]; // Mengambil kode area (misalnya (021) atau (031))
        const lastPart = match.split(" ").pop(); // Mengambil bagian akhir nomor (misalnya 8787 atau 88)
        
        // Menggunakan bagian kode area dan bagian akhir, mengganti angka tengah dengan *
        return `${areaCode} **** ${lastPart}`;
    })
    }
    return formattedPhoneNumber;
  };

  const handleShowPhone = (id) => {
    setIsShowMasking((prevState) => {
      const isShown = prevState.phone.shown && prevState.phone.id === id;
      return {
        ...prevState,
        phone: {
          ...prevState.phone,
          id: id,
          shown: !isShown,  
        },
      };
    });
  };

  let phoneNumber = item?.phone || '';

  return (
    <div onClick={() => handleShowPhone(item?.id)} style={{ cursor: "pointer" }}>
      {isShowMasking.phone.shown && isShowMasking.phone.id === item?.id 
        ? phoneNumber 
        : maskPhoneNumber(phoneNumber)
      }
      {phoneNumber.length > 0 ? (
      <i 
        className={`fa ${isShowMasking.phone.shown && isShowMasking.phone.id === item?.id ? "fa-eye-slash" : "fa-eye"} ml-2`} 
        aria-hidden="true" 
      />
      ) : '-'}
    </div>
  );
};

export const MaskEmail = ({ item }) => {
  const [isShowMasking, setIsShowMasking] = useState({
    email: {
      shown: false,
      id: '' 
    },
  });

  const maskEmail = (email) => {
    return email.replace(/(?<=.{3}).(?=.*@)/g, "*");
  };

  const handleShowEmail = (id) => {
    setIsShowMasking((prevState) => {
      const isShown = prevState.email.shown && prevState.email.id === id;
      return {
        ...prevState,
        email: {
          ...prevState.email,
          id: id,
          shown: !isShown,  
        },
      };
    });
  };

  let email = item?.email || '';

  return (
    <div onClick={() => handleShowEmail(item?.id)} style={{ cursor: "pointer" }}>
      {isShowMasking.email.shown && isShowMasking.email.id === item?.id 
        ? email 
        : maskEmail(email)
      }
      {email.length > 0 ? (
        <i 
          className={`fa ${isShowMasking.email.shown && isShowMasking.email.id === item?.id ? "fa-eye-slash" : "fa-eye"} ml-2`} 
          aria-hidden="true" 
        />

      ) : '-' }
    </div>
  );
};

export const formatGUID = (value) => {
  let maskedValue = '';
    let rawValue = value.replace(/[^a-zA-Z0-9]/g, ''); // Remove only non-alphanumeric characters for the masking process

    let j = 0; // To track where in the rawValue we are

    // Iterate over each character of the original value
    for (let i = 0; i < value.length; i++) {
      if (/[^a-zA-Z0-9]/.test(value[i])) {
        // If the character is a non-alphanumeric (like '-'), keep it as is
        maskedValue += value[i];
      } else {
        // Otherwise, replace the character with a masked one
        maskedValue += j < rawValue.length - 4 ? '*' : rawValue[j]; // Mask all characters except the last 4
        j++;
      }
    }
    return maskedValue;
};

export const listsPaginate = (jumlah_page, current_page, dispatch, search, datas, active) => {
  let item = [];
  for (let index = 1; index <= jumlah_page; index++) {
      item.push(index);
  }

  return item.map((page) => {
      const isActive = current_page === page ? "active" : "";
      const isPageVisible = (
          page === current_page || 
          page === current_page - 1 || 
          page === current_page + 1
      );

      // Menampilkan halaman yang relevan
      if (isPageVisible) {
          return (
              <li key={page} className={`page-item ${isActive}`}>
                  <a 
                      className="page-link" 
                      href="#" 
                      onClick={(e) => handlePageChange(e, page, dispatch, search, datas, active)}
                  >
                      {page}
                  </a>
              </li>
          );
      }
      return null; // Jika halaman tidak relevan, tidak menampilkan apa-apa
  });
};

export const handlePageChange = (e, pageNumber, dispatch, search, listsData, active) => {
  e.preventDefault();
    if(active === undefined){
      dispatch(listsData(pageNumber, search)); // Menangani dispatch ke store
    }else{
      dispatch(listsData(active, pageNumber, search));
    }
  }
  
export const dateFormat = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('id-ID', options);
  return formattedDate;
}

export const convertNumberPhone = (phone) => {
  // Remove all non-numeric characters from the phone number
  const cleanedPhone = phone?.replace(/[^0-9]/g, "");

  // Check if the phone number starts with '0'
  if (cleanedPhone?.startsWith("0")) {
    // Replace '0' at the start with '62'
    return "62" + cleanedPhone?.slice(1);
  }

  // If the phone number does not start with '0', return it as is
  return cleanedPhone;
};