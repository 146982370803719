import { Avatar, Spin, Grid, Badge, Select, Input, InputNumber, DatePicker, Modal, Button, Dropdown } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useSelector, useDispatch } from "react-redux";
import { useState ,useEffect, useRef, useCallback } from "react";
import { officeAllList } from '../../../store/actions/memberActions'
import { provinceList, citybyprovinceList} from '../../../store/actions/lookupActions'
import { mitraList } from "../../../store/actions/mitraActions";
import { leadWebsiteSave } from "../../../store/actions/leadWebsiteActions";
import { clearMessages, getMessages, getNextMessages, handleWebSocket, newMessage, deleteMessage } from "../../../store/actions/whatsappActions";
import axios from "axios";
import echo from "../../../utils/echo";
import {
  convertNumberPhone,
  IDRFormat,
} from "../../../utils/helper";
import moment from "moment";

const FromThemText = ({text, timestamp, setReplyTarget, messageId, selectedName, reply}) => {   //component untuk text dari WA
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    { key: "1", label: "Reply" },
    { key: "2", label: "Copy"}
  ];

  const handleMenuClick = ({ key }) => {
    if (key === "1") {
      setReplyTarget({
        name: selectedName,
        id: messageId,
        replyText: text
      })
    } else if (key === "2") {
      navigator.clipboard.writeText(text).then(() => {
        alert("Text copied to clipboard!");
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "100%",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "rgba(245, 245, 245)",
          display: "flex",
          maxWidth: "100%",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          whiteSpace: "pre-line",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: isHovered ? "auto" : "none",
          }}
        >
          <Dropdown menu={{ items, onClick: handleMenuClick }}  trigger={["click"]} overlayStyle={{ width: "200px" }}>
            <button style={{border: "none", outline: "none", backgroundColor: "transparent"}}><i className="fa fa-angle-down text-secondary" style={{fontSize: "20px"}}></i></button>
          </Dropdown>
        </div>
        <div className="d-flex flex-column" hidden={!reply} style={{backgroundColor: "rgb(235, 235, 235)", maxWidth: "100%", height: "50px", marginBottom: "3px", padding: "5px", borderRadius: "10px", overflow: "hidden"}}>
          <small style={{color: "#53bdeb", fontWeight: "bold"}}>{reply?.data?.text?.body ? "you" : selectedName}</small>
          <small 
            className="text-secondary"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
              overflow: "hidden",
            }}>{reply?.data?.text?.body 
              ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.text?.body 
              ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.document.filename
              ?? ""
            }</small>
        </div>
        <div className="d-flex" style={{whiteSpace: "pre-line", flexWrap: "wrap"}}>
          <span className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text}</span>
          <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto", fontSize: "10px"}}>{timestamp}</small>
        </div>
      </div>
    </div>
  )
}

const FromMeText = ({text, timestamp, setModalDelete, setReplyTarget, messageId, reply, selectedName}) => { //component untuk text dari kita
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    { key: "1", label: "Reply" },
    { key: "2", label: "Delete" },
    { key: "3", label: "Copy"}
  ];

  const handleMenuClick = ({ key }) => {
    if (key === "1") {
      setReplyTarget({
        name: "you",
        id: messageId,
        replyText: text
      })
    } else if (key === "2") {
      setModalDelete({shown: true, data: {id: messageId}})
    } else if (key === "3") {
      navigator.clipboard.writeText(text).then(() => {
        alert("Text copied to clipboard!");
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "100%",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          padding: "8px",
          borderRadius: "10px",
          backgroundColor: "rgba(220, 248, 198)",
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
          flexDirection: "column",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
         <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: isHovered ? "auto" : "none",
          }}
        >
          <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]} overlayStyle={{ width: "200px" }}>
            <button style={{border: "none", outline: "none", backgroundColor: "transparent"}}><i className="fa fa-angle-down text-secondary" style={{fontSize: "20px"}}></i></button>
          </Dropdown>
        </div>
        <div className="d-flex flex-column" hidden={!reply} style={{backgroundColor: "#d1f4cc", maxWidth: "100%", height: "50px", marginBottom: "3px", padding: "5px", borderRadius: "10px", overflow: "hidden"}}>
          <small style={{color: reply?.data?.text?.body ? "#53bdeb" : "#14d1a0", fontWeight: "bold"}}>{reply?.data?.text?.body ? "you" : selectedName}</small>
          <small 
            className="text-secondary"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
              overflow: "hidden",
            }}>{reply?.data?.text?.body 
                ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.text?.body 
                ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.document.filename
                ?? ""
            }    
          </small>
        </div>
        <div className="d-flex" style={{whiteSpace: "pre-line", flexWrap: "wrap"}}>
          <span className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text}</span>
          <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto", marginTop: "auto", fontSize: "10px", alignSelf: "end"}}>{timestamp}</small>
        </div>
      </div>
    </div>
  );
}

const DocumentMessage = ({text, timestamp, setReplyTarget, messageId, selectedName, reply}) => { //component untuk text dari WA yang berformat document
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    { key: "1", label: "Reply" },
    { key: "2", label: "Copy"}
  ];

  const handleMenuClick = ({ key }) => {
    if (key === "1") {
      setReplyTarget({
        name: selectedName,
        id: messageId,
        replyText: text
      })
    } else if (key === "2") {
      navigator.clipboard.writeText(text).then(() => {
        alert("Text copied to clipboard!");
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "100%",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        width: "50%",
      }}
    >
        <div style={{
          height: !reply ? "130px" : "200px", 
          display: "flex", 
          maxWidth: "100%",
          flexDirection: "column",
          justifyContent: "end", 
          borderRadius: "10px", 
          backgroundImage: "url(https://pnghq.com/wp-content/uploads/pdf-logo-png-png-no-watermark-41801-300x296.png)",
          backgroundSize: "auto 80px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'center',
          backgroundColor: "rgba(245, 245, 245)",
          width: "100%",
          position: "relative",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <div
            style={{
              backgroundColor: "rgba(235, 235, 235)",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              width: "100%",
            }}
          >
              <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                opacity: isHovered ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                pointerEvents: isHovered ? "auto" : "none",
              }}
            >
              <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]} overlayStyle={{ width: "200px" }}>
                <button style={{border: "none", outline: "none", backgroundColor: "transparent"}}><i className="fa fa-angle-down text-secondary" style={{fontSize: "20px"}}></i></button>
              </Dropdown>
            </div>
            <div className="d-flex flex-column"  hidden={!reply} style={{backgroundColor: "rgb(230, 230, 230)", maxWidth: "100%", height: "50px", marginBottom: "3px", padding: "5px", borderRadius: "10px", overflow: "hidden"}}>
              <small style={{color: "#53bdeb", fontWeight: "bold"}}>{reply?.data?.text?.body ? "you" : selectedName}</small>
              <small 
                className="text-secondary"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                  overflow: "hidden",
                }}>{reply?.data?.text?.body 
                  ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.text?.body 
                  ?? reply?.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.document.filename
                  ?? ""
                }</small>
            </div>
            <div className="d-flex" style={{whiteSpace: "pre-line", flexWrap: "wrap"}}>
              <a href="#" className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text} <i className="fa fa-download" aria-hidden="true"></i></a>
              <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto", fontSize: "10px", justifyContent: "end"}}>{timestamp}</small>
            </div>  
          </div>
        </div>
    </div>
  )
}

const WhatsappBoardIndex = () => {
    let dispatch = useDispatch();
    const nextContact = useRef(null);
    const nextArchive = useRef(null);
    const loadingContacts = useRef(false);
    const [loadingContactState, setLoadingContactState] = useState(false);
    const [contacts, setContacts] = useState([])
    const [searchContacts, setSearchContacts] = useState("");
    const [showContacts, setShowContacts] = useState(true);
    const [archive, setArchive] = useState(false);
    const archiveReff = useRef(false)

    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    
    const [newMessageState, setNewMessageState] = useState("");
    const [showChat, setShowChat] = useState(false);
    const {messages, loading, next} = useSelector(state => state.whatsapp)
    const chatRef = useRef(null)
    const contactBoxRef = useRef(null)
    const phoneNumRef = useRef("");
    const skipAutoScroll = useRef(false);
    const skipNextContact = useRef(false);
    const scrollPosition = useRef(null);
    const loadingRef = useRef(false);
    const [numberExists, setNumberExists] = useState(false);
    const nextRef = useRef();
    const { useBreakpoint } = Grid;
    const dataLogin = useSelector((state) => state.login);
    let { userInfo } = dataLogin;

    const [modalLeads, setModalLeads] = useState({
      shown: false,
      data: {},
    });

    const [modalDelete, setModalDelete] = useState({
      shown: false,
      data: {},
    });

    const [modalArchive, setModalArchive] = useState({
      shown: false,
      data: {},
    });

    const [modalUnarchive, setModalUnarchive] = useState({
      shown: false,
      data: {},
    });

    const [replyTarget, setReplyTarget] = useState({})

    useEffect(() => {
      if (!userInfo || !userInfo.permission) return;
      if (userInfo.user.roles[0].name == "Super Admin") return;
      if (!userInfo.permission.includes("whatsapp-board-read")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
    }, []);

    useEffect(async () => {
      const {data} = await axios.get(`lead-website/check-phone/${selectedContact}`)
      setNumberExists(data?.isFound);
    }, [selectedContact])

    const { md } = useBreakpoint();
    const startX = useRef("");

    const [swipeDirection, setSwipeDirection] = useState("");


    const handleTouchStart = (e) => {
      startX.current = e.touches[0].clientX; // Store initial touch position
    };

    const handleTouchEnd = (e) => {
      const endX = e.changedTouches[0].clientX; // Get touch end position
      const diffX = endX - startX.current;

      if (diffX > 50) setSwipeDirection("Right");
      else if (diffX < -50) setSwipeDirection("Left");
    };

    useEffect(() => {
      if(swipeDirection === "Left"){
        setShowChat(true);
        setShowContacts(false);
      }else if(swipeDirection === "Right"){
        setShowChat(false);
        setShowContacts(true);
      }
    }, [swipeDirection]);

    async function getArchives() {
      updateLoadingState(true)
      const {data} = await axios.get(`contacts/getArchives`)
      nextArchive.current = data.current_page + 1;
      
      setContacts( () => {
        const filteredContacts = [...data.data].filter(
          (contact, index, self) =>
            index === self.findIndex((c) => c.phone === contact.phone)
        );
        return filteredContacts;
      })

      updateLoadingState(false)
    }

    async function getNextArchives() {
      updateLoadingState(true)
      const {data} = await axios.get(`contacts/getArchives?page=${nextArchive.current}`)

      nextArchive.current = nextArchive.current + 1

      setContacts(
        prevContacts => {
        const mergedContacts = [...prevContacts, ...data.data].filter(
          (contact, index, self) =>
            index === self.findIndex((c) => c.phone === contact.phone)
        );
        return mergedContacts;
      })
    
      updateLoadingState(false)
    }

    async function getContacts() {
      updateLoadingState(true)
      const {data} = await axios.get(`contacts`)
      nextContact.current = data.current_page + 1;
      
      setContacts( () => {
        const filteredContacts = [...data.data].filter(
          (contact, index, self) =>
            index === self.findIndex((c) => c.phone === contact.phone)
        );
        return filteredContacts;
      })

      updateLoadingState(false)
    }

    async function getNextContacts() {
      updateLoadingState(true)
      const {data} = await axios.get(`contacts?page=${nextContact.current}`)

      nextContact.current = nextContact.current + 1

      setContacts(
        prevContacts => {
        const mergedContacts = [...prevContacts, ...data.data].filter(
          (contact, index, self) =>
            index === self.findIndex((c) => c.phone === contact.phone)
        );
        return mergedContacts;
      })
    
      updateLoadingState(false)
    }

    async function handleSearchContacts(e) {
      e.preventDefault()
      skipNextContact.current = true
      if(searchContacts === "") {
        getContacts();
      }else {
        const {data} = await axios.get(`contacts/searchByName/${searchContacts}`)
        setContacts(data)  
      }
    }

    function updateLoadingState(value) {
      loadingContacts.current = value;
      setLoadingContactState(value);
    }

    useEffect(() => {
      window.scrollTo({ top: 0 });
      document.body.style.overflow = "hidden"; // Disable scrolling
      getContacts()

      return () => {
        document.body.style.overflow = "auto"; // Re-enable scrolling when leaving
      };  
    }, []);

    useEffect(() => {
      if (selectedContact === null) return;
    
      skipAutoScroll.current = false;
      dispatch(getMessages(selectedContact));
      setShowChat(true);
      setShowContacts(false);
    }, [selectedContact]);

    useEffect(() => {
      if (chatRef.current && !skipAutoScroll.current) {    //auto scroll kebawah saat messages berubah
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }else if(skipAutoScroll.current){
        chatRef.current.scrollTop = chatRef.current.scrollHeight - scrollPosition.current;
      }
    }, [messages])

    
    useEffect(() => {nextRef.current = next}, [next])
    useEffect(() => {loadingRef.current = loading}, [loading])
    useEffect(() => {phoneNumRef.current = selectedContact}, [selectedContact])
    useEffect(() => {archiveReff.current = archive}, [archive])

    useEffect(() => {
        skipAutoScroll.current = false;
  
        const chatElement = chatRef.current;
        if (chatElement) {
          chatElement.addEventListener("scroll", handleScroll); //saat whatsapp drawer terbuka add event listener buat scroll
        }

        const contactsElement = contactBoxRef.current;
        if (chatElement) {
          contactsElement.addEventListener("scroll", handleContactsScroll); 
        }

        //web socket connection
        const channel = echo.channel("Whatsapp")
        .listen('WhatsappMessage', (x) => {
          const phoneNum = x.data.data[0].changes?.[0]?.value.contacts[0].wa_id;
          skipAutoScroll.current = true;
          
          dispatch(handleWebSocket(x.data, phoneNum, phoneNumRef.current , phoneNum == phoneNumRef.current));

          setContacts((prevData) => {
            const arr = [...prevData];
            const index = arr.findIndex(contact => contact.phone === phoneNum);
            if(index !== -1) {
              const item = arr.splice(index, 1)[0]; // Remove the item at the given index
              arr.unshift(item); // Add it to the start
            }
    
            return arr
          });
        })
        .listen('NewContact', (x) => {
          const data = x.data;
          if(data?.last_message === undefined){
            setContacts((prevData) => {
              let arr = [...prevData];
              const index = arr.findIndex(contact => contact.phone === data?.phone);
              if(index == -1) {
                arr = [data, ...prevData]
              }else {
                arr[index].updated_at = data.updated_at
                arr[index].is_unread = data.is_unread
              }
      
              return arr
            });
          }else {
            setContacts((prevData) => {
              let arr = [...prevData];
              const index = arr.findIndex(contact => contact.phone === data.phone);
              if(index !== -1) {
                arr[index].last_message = data.last_message //ketemu
                arr[index].updated_at = data.updated_at
                arr[index].is_unread = data.is_unread
              }else{
                arr = [data, ...prevData]
              }
              
              return arr
            });
          }
        })
        .error((error) => console.log("WebSocket error:", error));
      
      return () => {
        channel.stopListening('WhatsappMessage');
        channel.stopListening('NewContact');
        echo.leaveChannel('Whatsapp');
        chatRef?.current?.removeEventListener("scroll", handleScroll);
        dispatch(clearMessages());
      }
    }, [])

    const handleNewMessage = async (e) => {  //api request untuk messsage baru
      e.preventDefault();
      
      const x = newMessageState
      skipAutoScroll.current = false;
      setNewMessageState("")

      setContacts((prevData) => {
        const arr = [...prevData];
        const index = arr.findIndex(contact => contact.phone === selectedContact);
        const item = arr.splice(index, 1)[0]; // Remove the item at the given index
        arr.unshift(item); // Add it to the start

        return arr
      });

      contactBoxRef.current.scrollTop = 0;

      dispatch(newMessage(x, selectedContact, replyTarget.id));
      setReplyTarget({})
    }

    const handleScroll = useCallback(() => {
      //function untuk handle scroll
      if (!chatRef.current) return;
      if (chatRef.current.scrollTop === 0  && !loadingRef.current && nextRef.current !== null) {
        scrollPosition.current = chatRef.current.scrollHeight - chatRef.current.scrollTop;
        skipAutoScroll.current = true;
        dispatch(getNextMessages(nextRef.current, phoneNumRef.current));
      }
    }, []);

    const handleContactsScroll =  useCallback(() => {
      if (!contactBoxRef.current) return;
      const scrollPosition = Math.round(
        contactBoxRef.current.scrollHeight - contactBoxRef.current.scrollTop
      );
      if (Math.abs(scrollPosition - contactBoxRef.current.clientHeight) <= 1 && !loadingContacts.current && !skipNextContact.current) {
        skipNextContact.current = true
        if(archiveReff.current){
          getNextArchives()
        }else {
          getNextContacts()
        }
      }
      skipNextContact.current = false
    }, [archive]);

    const showLeads = () => {
      setModalLeads({
        shown: true,
        data: {
          name: selectedName,
          phone: selectedContact
        },
      });
    };

    const sendToArchive = async (phone) => {
      skipNextContact.current = true
      await axios.post(`contacts/sendToArchive/${phone}`)
    }

    const unarchive = async (phone) => {
      skipNextContact.current = true
      await axios.post(`contacts/unarchive/${phone}`)
    }

    const clearChatBox = () => {
      setSelectedContact(null)
      setSelectedName(null)

      setTimeout(() => {
        dispatch(clearMessages());
      }, 100);
    }

    return (
        <div className="row"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
            <div className="col-lg-12 d-flex" 
              style={{minHeight:"87vh"}}
            >
                <div style={{
                  flex: 1, 
                  backgroundColor: "white", 
                  height: "87vh", 
                  overflowX: "hidden",
                  display: md || showContacts ? "flex" : "none",
                  flexDirection: "column"
                }}>
                    <form onSubmit={handleSearchContacts} hidden={archive} className="input-group my-2 d-static" style={{ width: "94%", margin: "7px", backgroundColor: "#ededed", borderRadius: "8px"}}>
                        <span className="input-group-text border-0" style={{borderRadius: "8px"}}>
                            <i className="fa fa-search" style={{color: '#128C7E'}}></i>
                        </span>
                        <input
                            type="text"
                            placeholder="search"
                            className="form-control"
                            style={{
                              borderRadius: "8px",
                              backgroundColor: "#ededed",
                              border: "none",
                            }}
                            value={searchContacts}
                            onChange={(e) => setSearchContacts(e.target.value)}
                        />
                    </form>

                    <div className="w-100 d-flex border-bottom" hidden={!archive} style={{height: "50px", cursor: "pointer", alignItems: "center"}} 
                        onClick={() =>  { 
                          if(!loading){
                            setArchive(false)
                            getContacts()
                            clearChatBox()
                          }
                        }}>
                        <i className="fa fa-arrow-left mx-4 text-secondary" style={{fontSize: "20px"}}></i>
                        <p style={{alignItems: "center", border: 0, margin: 0, padding: 0}}>Archived</p>
                    </div>

                    <div className="w-100 d-flex border-bottom" hidden={archive} style={{height: "40px", cursor: "pointer", alignItems: "center"}} 
                        onClick={() => {
                          if(!loading){
                            setArchive(true)
                            getArchives()
                            clearChatBox()
                          }
                        }}>
                        <i className="fa fa-archive mx-3" style={{fontSize: "20px", color: '#128C7E'}}></i>
                        <p className="text-secondary" style={{alignItems: "center", border: 0, margin: 0, padding: 0}}>Archived</p>
                    </div>

                    <div className="d-flex flex-column" style={{gap: "10px", overflowY: "scroll", flex: "1"}} ref={contactBoxRef}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}>{loadingContactState ? <Spin size="large" style={{position: "fixed", zIndex: 1000}} /> : ""}</div>
                        {contacts?.map((element, key) => {
                          const date = new Date(element?.updated_at)
                          const currentDate = new Date()

                          const diffMs = Math.abs(date - currentDate);
                          const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));
                          const diffWeeks = Math.round(diffMs / (1000 * 60 * 60 * 24 * 7));

                          let timestamp;

                          if(diffDays === 0){
                            const hours = date.getHours().toString().padStart(2, "0"); // Ensures 2-digit format
                            const minutes = date.getMinutes().toString().padStart(2, "0");
                            timestamp = `${hours}:${minutes}`;
                          }else if(diffDays === 1){
                            timestamp = "Yesterday"
                          }else if(diffDays > 1 && diffDays < 7){
                            timestamp = `${diffDays} days ago`
                          }
                          else if(diffDays >= 7 && diffDays < 14){
                            timestamp = "1 week ago"
                          }else if(diffDays >= 14 && diffDays < 70){
                            timestamp = `${diffWeeks} weeks ago`
                          }else{
                            const day = date.getDate().toString().padStart(2, "0"); // Ensures 2-digit format
                            const month = (date.getMonth() + 1).toString().padStart(2, "0");
                            timestamp = `${day}/${month}`;
                          }

                          return (
                          <div 
                            className="d-flex p-2" 
                            style={{
                              gap: "10px", 
                              border: "none",
                              backgroundColor: selectedContact == element.phone ? "#EEEEEE" : "",
                              position: "relative"
                            }}
                            key={key}
                            onClick={() => {
                              setSelectedContact(convertNumberPhone(element.phone))
                              setSelectedName(element.name)
                            }}
                            >
                      
                            <Avatar size={40} icon={<i className="fa fa-user"></i>}/>
                            <div className="d-flex flex-column">
                                <b style={{width: "130px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{element.name}</b>
                                <small className="text-secondary">{element.last_message ? element.last_message : "hi im using whatsapp!"}</small>
                            </div>
                            <div
                              style={{
                                marginLeft: "auto",
                                marginTop: "auto",
                                position: "absolute",
                                right: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                paddingRight: "5px"
                              }}
                            >
                              <small className="text-secondary">{timestamp}</small>
                              <Badge
                                style={{
                                  backgroundColor: "#25D366",
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "20px",
                                  color: "#25D366",
                                  fontSize: "10px",
                                }}
                                count={element.is_unread ? 1 : 0}
                              >
                              </Badge>
                            </div>
                          </div>
                          )
                        })}
                    </div>
                </div>
                
                <div style={{
                  flex: 2.5, 
                  backgroundColor: "#ECE5DD", 
                  height:"87vh", 
                  position: "relative", 
                  overflowX: "hidden", 
                  flexDirection: "column",
                  display: md ||showChat ? "flex" : "none"
                }}>
                    <div style={{width: "100%", backgroundColor: "#ededed", display: selectedContact == null ? "none" : "flex", padding: "2px"}}>          
                        <div 
                          className="d-flex p-2" 
                          style={{
                            gap: "10px", 
                            border: "none",
                          }}
                          >
                    
                          <Avatar size={40} icon={<i className="fa fa-user"></i>}/>
                          <div className="d-flex flex-column">
                              {selectedName}
                              <small className="text-secondary">{selectedContact}</small>
                          </div>
                        </div>

                        <Dropdown 
                          style={{display: "block"}}
                          trigger={["click"]} 
                          overlay={
                            <div style={{width: "170px", backgroundColor: "white", padding: "5px"}}>
                              <button style={{display: numberExists ? "none" : "block", border: "none", outline: "none", backgroundColor: "transparent", padding: "5px"}} onClick={showLeads}>Add To Leads <i className="fa fa-users" style={{marginLeft: "34px"}}></i></button>
                              <button style={{border: "none", outline: "none", backgroundColor: "transparent", padding: "5px"}} hidden={archive} onClick={() => setModalArchive({shown: true})}>Archive Contact <i className="fa fa-archive" style={{marginLeft: "20px"}}></i></button>
                              <button style={{border: "none", outline: "none", backgroundColor: "transparent", padding: "5px"}} hidden={!archive} onClick={() => setModalUnarchive({shown: true})}>Unarchive Contact <i className="fa fa-inbox" style={{marginLeft: "7px"}}></i></button>
                            </div>
                          }
                        >
                          <button style={{marginLeft: "auto", marginRight: "10px",border: "none", outline: "none", backgroundColor: "transparent"}}><i className="fa fa-angle-down text-secondary" style={{fontSize: "20px"}}></i></button>
                        </Dropdown>
                        
                    </div>

                    <div style={{
                      flex: "1", 
                      overflowY: "scroll", 
                      overflowX: "hidden",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none", 
                      WebkitOverflowScrolling: "touch",
                      padding: "10px", 
                      }} ref={chatRef}
                    >
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}>{loading ? <Spin size="large" style={{position: "fixed", zIndex: 1000}} /> : ""}</div>

                        {
                            messages.map((element, key) => {
                                Date.prototype.toString = function () {
                                    return this.toLocaleString(); // Automatically uses local format
                                };

                                const formattedTime = new Date(element.created_at)
                                const x = formattedTime.toString().split(" ");
                                const y = x[1].split(":");
                                const timestamp = y[0]+":"+y[1]+" "+x[2]

                                switch(element.data?.[0]?.changes?.[0]?.value?.messages?.[0].type){
                                  case "document": {
                                        let reply = messages.find((e) => {
                                          if(e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id){
                                            return e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id === element.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.context?.id;
                                          }else if(e.id){
                                            return e.id === element.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.context?.id
                                          }
                                        })
                                        if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                                          return [
                                          <div style={{width: "90%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "25px", marginLeft: "25px"}} key={`serperator-${key}`}></div>,
                                          <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,

                                          <DocumentMessage key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].document.filename} timestamp={timestamp} setReplyTarget={setReplyTarget} messageId={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].id} selectedName={selectedName} reply={reply}/>
                                        ]
                                      }
                    
                                      return <DocumentMessage key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].document.filename} timestamp={timestamp}  setReplyTarget={setReplyTarget} messageId={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].id} selectedName={selectedName} reply={reply}/>
                                  }
                                  case "text": {
                                      let reply = messages.find((e) => {
                                        if(e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id){
                                          return e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id === element.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.context?.id;
                                        }else if(e.id){
                                          return e.id ===  element.data?.[0]?.changes?.[0]?.value?.messages?.[0]?.context?.id
                                        }
                                      })
                                      if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                                          return [
                                          <div style={{width: "90%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "25px", marginLeft: "25px"}} key={`serperator-${key}`}></div>,
                                          <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,

                                          <FromThemText key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].text.body} timestamp={timestamp}  setReplyTarget={setReplyTarget} messageId={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].id} selectedName={selectedName} reply={reply}/>
                                        ]
                                      }
                    
                                      return <FromThemText key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].text.body} timestamp={timestamp}  setReplyTarget={setReplyTarget} messageId={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].id} selectedName={selectedName} reply={reply}/>
                                  }
                                  default: {
                                    try {
                                      let reply = messages.find((e) => {
                                            if(e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id){
                                              return e.data?.[0]?.changes?.[0]?.value?.messages?.[0].id === element.data?.context?.id;
                                            }else if(e.id){
                                              return e.id === element.data?.context?.id
                                            }
                                          })
                                      if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                                        return [
                                        <div style={{width: "90%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "25px", marginLeft: "25px"}} key={`serperator-${key}`}></div>,
                                        <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,
                                        <FromMeText 
                                          key={key} 
                                          text={element.data.text.body} 
                                          timestamp={timestamp} 
                                          setModalDelete={setModalDelete} 
                                          setReplyTarget={setReplyTarget} 
                                          messageId={element.id} 
                                          reply={reply}
                                          selectedName={selectedName}/>
                                        ]
                                      }
                                      return <FromMeText key={key} text={element.data.text.body} timestamp={timestamp} setModalDelete={setModalDelete} setReplyTarget={setReplyTarget} messageId={element.id} reply={reply} selectedName={selectedName}/>
                                    }catch(e){
                                    }
                                  }
                                }
                            })
                        }

                        {messages?.length === 0 && !loading && selectedContact !== null ? <h2 className="text-center w-100 mt-4">No messages yet!</h2> : ""}
                    </div>

                    <div style={{ width: "100%", display: selectedContact == null ? "none" : "block", marginTop: "auto", backgroundColor: "#EEEEEE" }}>
                      <form onSubmit={handleNewMessage} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px" }}>
                        <div style={{ width: "90%", padding: "5px" }}>
                          <div className="d-flex flex-column" hidden={replyTarget?.name === undefined} style={{backgroundColor: "#DADCDF", width: "100%", marginBottom: "10px", height: "60px", borderRadius: "10px", padding: "10px", overflow: "hidden"}}>
                            <small style={{color: "#06CF9C", fontWeight: "bold"}}>{replyTarget?.name}</small>
                            <small 
                              className="text-secondary" 
                              style={{
                              width: "100%",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                              overflow: "hidden",
                            }}>{replyTarget?.replyText}</small>
                          </div>
                          <textarea
                            style={{ width: "100%", borderRadius: "10px", border: "none", backgroundColor: "white", resize: "none"}}
                            placeholder="Type a message"
                            onChange={(e) => setNewMessageState(e.target.value)} 
                            value={newMessageState}
                            rows={1}
                          />
                        </div>
                        <div
                          className="d-flex flex-column"
                          style={{
                            width: "10%",
                            justifyContent: "flex",
                            height: replyTarget?.name === undefined ? "100%" : "110px"
                          }}
                        >
                          <button
                            className="text-secondary"
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              border: "none",
                              outline: "none",
                              marginBottom: "auto",
                              display: replyTarget?.name === undefined ? "none" : "block"
                            }}
                            type="button"
                            onClick={() => setReplyTarget({})}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                          <button
                            style={{
                              width: "100%",
                              padding: "10px",
                              borderRadius: "10px",
                              backgroundColor: "#25d366",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "none",
                              height: "40px",
                              marginTop: "auto"
                            }}
                          >
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>

              {/* Modal LeadsWebsite  */}
            <Modal
              title="Add to Leads"
              onCancel={() => setModalLeads({ ...modalLeads, shown: false })}
              open={modalLeads.shown}
              style={{ top: 20 }}
              footer={
                <div style={{ width: "100%", display: "flex" }}>
                  <Button
                    style={{ width: "100%", backgroundColor: "#1aa6ed" }}
                    onClick={() => setModalLeads({ ...modalLeads, shown: false })}
                    htmlType="button"
                  >
                    <span style={{ color: "white" }}>Close</span>
                  </Button>
                </div>
              }
            >
              <LeadsModal modalLeads={modalLeads} setModalLeads={setModalLeads}/>
            </Modal>

            {/* Modal DeleteWebsite  */}
            <Modal
              onCancel={() => setModalDelete({ ...modalDelete, shown: false })}
              open={modalDelete.shown}
              centered
              footer={
                <div className="d-flex" style={{justifyContent: "end"}}>
                  <button className="btn" style={{color: "#008069", borderRadius: "20px", border: "1px solid lightgray"}} onClick={() => setModalDelete({...modalLeads, shown: false})}>cancel</button>
                  <button className="btn" style={{backgroundColor: "#008069", color: "white", borderRadius: "20px"}} 
                          onClick={() => {
                            dispatch(deleteMessage(modalDelete.data.id)); 
                            setModalDelete({...modalDelete, shown: false})}}
                  >delete for everyone</button>
                </div>
              }
            >
              Delete message?
            </Modal>

            {/* Modal Archive  */}
            <Modal
              onCancel={() => setModalArchive({ shown: false })}
              open={modalArchive.shown}
              centered
              footer={
                <div className="d-flex" style={{justifyContent: "end"}}>
                  <button className="btn" style={{color: "#008069", borderRadius: "20px", border: "1px solid lightgray"}} onClick={() => setModalArchive({...modalLeads, shown: false})}>cancel</button>
                  <button className="btn" style={{backgroundColor: "#008069", color: "white", borderRadius: "20px"}} 
                          onClick={() => {
                            sendToArchive(selectedContact)
                            setModalArchive({shown: false})
                            getContacts()
                            clearChatBox()
                          }}
                  >Archive</button>
                </div>
              }
            >
              Send contact to Archives?
            </Modal>

            {/* Modal Unarchive  */}
            <Modal
              onCancel={() => setModalUnarchive({ shown: false })}
              open={modalUnarchive.shown}
              centered
              footer={
                <div className="d-flex" style={{justifyContent: "end"}}>
                  <button className="btn" style={{color: "#008069", borderRadius: "20px", border: "1px solid lightgray"}} onClick={() => setModalUnarchive({...modalLeads, shown: false})}>cancel</button>
                  <button className="btn" style={{backgroundColor: "#008069", color: "white", borderRadius: "20px"}} 
                          onClick={() => {
                            unarchive(selectedContact)
                            setModalUnarchive({shown: false})
                            getArchives()
                            clearChatBox()
                          }}
                  >Unarchive</button>
                </div>
              }
            >
              Unarchive this contact?
            </Modal>
        </div>
    )
}

const LeadsModal = (props) => {
  const {modalLeads, setModalLeads} = props
  
  const { listsProvince } = useSelector(state => state.provinceList)
  const { listsCity } = useSelector(state => state.citybyprovinceList)
  const { listsOffice } = useSelector(state => state.officeAllList)
  const { listsMitra } = useSelector(state => state.mitraList)

  const [office, setOffice] = useState("");
  const [mitra, setMitra] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [tenor, setTenor] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [referenceUrl, setReferenceUrl] = useState("");
  const [note, setNote] = useState("");
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setName(modalLeads?.data?.name);
    setPhone(modalLeads?.data?.phone);
    setUpdatedBy(JSON.parse(localStorage.getItem("userInfo")).user.name);
  }, [modalLeads]);

  let dispatch = useDispatch()

  useEffect(() => {
      dispatch(officeAllList())
      dispatch(provinceList())
      dispatch(mitraList())
  }, [dispatch])

  const handleChangeProvince = (e) => {
    const value = JSON.parse(e)
    setProvince(value.name)
    dispatch(citybyprovinceList(value.id))
  }

  const handleAdd = () => {
    const LeadsData = {
      name,
      email,
      phone,
      updatedBy,
      propertyPrice,
      loanAmount,
      downPayment,
      tenor,
      city,
      province,
      district,
      referenceUrl,
      note,
      remarks,
      mitra,
      office
    }
    setModalLeads({ shown: false })
    dispatch(leadWebsiteSave(LeadsData))
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        height: "100%",
      }}

      key={modalLeads?.data?.id}
    >
      <div style={{ width: "100%" }}>
        <label for="office">Office</label>
        <Select 
              style={{ color: 'gray', display: 'block'}}
              onChange={(e) => setOffice(e)} 
            >
              {
                listsOffice.map((item, index) => {
                  return(
                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                  )
                })
              }
        </Select>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="name">Name</label>
          <Input disabled style={{ color: 'gray'}} value={modalLeads?.data?.name}/>
        </div>
        <div style={{ width: '100%'}}>
          <label for="mitra">Mitra</label>
          <Select 
              style={{ color: 'gray', display: 'block'}}
              onChange={(e) => setMitra(e)}
              required
            >
              {
                listsMitra.map((item, index) => {
                  return(
                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="email">Email</label>
          <Input style={{ color: 'gray'}} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={{ width: '100%'}}>
          <label for="phone">Phone</label>
          <Input style={{ color: 'gray'}} value={phone} disabled />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <label for="created_at">Created At</label>
          <DatePicker 
            style={{ color: 'gray', display: 'block'}}
            format="DD MMMM YYYY"
            defaultValue={moment(modalLeads?.data?.created_at)}
            disabled
          />

        </div>
        <div style={{ width: "100%" }}>
          <label for="updated_at">Updated At</label>
          <DatePicker 
            style={{ color: 'gray', display: 'block'}}
            format="DD MMMM YYYY"
            defaultValue={moment(modalLeads?.data?.updated_at)}
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Updated By</label>
          <Input style={{ color: 'gray'}} defaultValue={JSON.parse(localStorage.getItem("userInfo")).user.name} onChange={(e) => setUpdatedBy(e.target.value)}/>
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Property Price</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%",}}
            onChange={(e) => setPropertyPrice(e)}
            formatter={(value) => IDRFormat(value)}
            parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Loan Amount</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%",}}
            onChange={(e) => setLoanAmount(e)}
            formatter={(value) => IDRFormat(value)}
            parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")}
          />
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Down Payment</label>
            <InputNumber
              style={{ color: 'gray', display: "block", width: "100%",}}
              onChange={(e) => setDownPayment(e)}
              formatter={(value) => IDRFormat(value)}
              parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")} // Removes 'Rp' and ',' on input
            />
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Tenor (tahun)</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%"}}
            onChange={(e) => setTenor(e)}
          />
        </div>
      </div>
      <div style={{width: "100%", border: "1px solid lightgray"}}>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Province</label>
           <Select 
              style={{ color: 'gray', display: 'block'}}
              onChange={(e) => handleChangeProvince(e)}
            >
              {
                listsProvince.map((item, index) => {
                  return(
                    <Select.Option value={JSON.stringify({id: item.id, name: item.name})} key={index}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">city</label>
          <Select
            style={{ color: 'gray', display: "block"}}
            onChange={(e) => setCity(e)}
          >
              {
                listsCity.map((item, index) => {
                  return(
                    <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                  )
                })
              }

          </Select>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label>district</label>
          <Input 
            style={{ color: 'gray'}}  
            onChange={(e) => setDistrict(e.target.value)}
          />
        </div>
        <div style={{ width: '100%'}}>
          <label>Reference URL</label>
          <Input 
            style={{ color: 'gray'}} 
            onChange={(e) => setReferenceUrl(e.target.value)}
          />
        </div>
      </div>
      <div>
        <label for="remarks">Note</label>
        <TextArea
          style={{ color: 'gray'}}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <div>
        <label for="remarks">Remarks</label>
        <TextArea
        key={modalLeads?.data?.remarks}
        style={{ color: 'gray'}}
          defaultValue={
            modalLeads?.data?.remarks ? modalLeads?.data?.remarks : ""
          }
          onChange={(e) => setRemarks(e.target.value)}
        />
      </div>

      <button className="btn btn-sm btn-info" onClick={handleAdd}>Add</button>
    </div>
  )
};

export default WhatsappBoardIndex