import {
    MESSAGE_REQUEST,
    MESSAGE_GET_SUCCESS,
    MESSAGE_FAIL,
    MESSAGE_GET_NEXT_SUCCESS,
    MESSAGE_ADD_SUCCESS,
    MESSAGE_UNREAD_ADD,
    MESSAGE_UNREAD_REMOVE,
    MESSAGE_CLEAR,
    MESSAGE_DELETE
} from '../constants/whatsappConstants'
import axios from 'axios'

function getCurrentDateTime() {  //untuk generate created_at saat membuat message baru agar bisa langsung tampil
    const now = new Date();

    now.setUTCHours(now.getUTCHours() + 7);

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
    const seconds = String(now.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

export const getMessages = (phoneNumber) => async(dispatch) => {
    try {
        dispatch({
            type: MESSAGE_REQUEST
        });

        const {data} = await axios.get(`${process.env.REACT_APP_API_URL_NEW}api/wa/${phoneNumber}`)

        dispatch({
            type: MESSAGE_UNREAD_REMOVE,
            phoneNum: phoneNumber
        })

        dispatch({
            type: MESSAGE_GET_SUCCESS,
            payload: data.data.data.reverse(),
            next: data.data.next_cursor,
        })

    } catch (err){
        dispatch({
            type: MESSAGE_FAIL,
            payload: err.message
        })
    }
}

export const clearMessages = () => async(dispatch) => {
    try {
        dispatch({
            type: MESSAGE_CLEAR
        })
    }catch (err) {
        dispatch({
            type: MESSAGE_FAIL
        })
    }
}

export const getNextMessages = (nextURL, phoneNumber) => async (dispatch) => {
    try {
        dispatch({
            type: MESSAGE_REQUEST
        });

        const {data} = await axios.get(`${process.env.REACT_APP_API_URL_NEW}api/wa/${phoneNumber}?cursor=${nextURL}`)
        
        dispatch({
            type: MESSAGE_GET_NEXT_SUCCESS,
            payload: data.data.data.reverse(),
            next: data.data.next_cursor
        })

        console.log("next message success")

    } catch (err){
        dispatch({
            type: MESSAGE_FAIL,
            payload: err.message
        })
    }
}

export const newMessage = (msg, phoneNumber, replyId) => async (dispatch) => {
    try {
        dispatch({
            type: MESSAGE_REQUEST
        });

        const msgdata = {
            data: {
              text: {
                body: msg
              },
              ...(replyId && { 
                context: { id: replyId } 
            })
            },
            created_at: getCurrentDateTime(),
        }

        await axios.post(`${process.env.REACT_APP_API_URL_NEW}api/wa/`, {
        "data": msg,
        "recepient": parseInt(phoneNumber),
        "reply": replyId
        });

        dispatch({ 
            type: MESSAGE_ADD_SUCCESS,
            payload: msgdata
        });

    } catch (err) {
        dispatch({
            type: MESSAGE_FAIL,
            payload: err.message
        });
    }
}

export const deleteMessage = (id) => async (dispatch) => {
    try{
        dispatch({
            type: MESSAGE_REQUEST
        });
        await axios.delete(`${process.env.REACT_APP_API_URL_NEW}api/wa/${id}`)
        dispatch({
            type: MESSAGE_DELETE,
            id: id
        });
    }catch(err){
        dispatch({
            type: MESSAGE_FAIL,
            payload: err.message
        });
    }
}

export const handleWebSocket = (msg, socketPhoneNum, phoneNum, isOpened) => (dispatch) => {
    try {
        if(!isOpened || socketPhoneNum !== phoneNum) {
            dispatch({ 
                type: MESSAGE_UNREAD_ADD,
                newMessage: socketPhoneNum
            });
        }else {
            dispatch({ 
                type: MESSAGE_ADD_SUCCESS,
                payload: msg
            });
        }


    } catch (err) {
        dispatch({
            type: MESSAGE_FAIL
        });
    }
}