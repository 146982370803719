import React from 'react'
import { Route, Redirect, useLocation, Switch } from 'react-router-dom'
import { useSelector }  from 'react-redux'

import Login from './screens/auth/Login'
import Dashboard from './screens/app/Dashboard'

import ParentIndex from './screens/app/Settings/Parent/Index'
import ParentCreate from './screens/app/Settings/Parent/Create'
import ParentEdit from './screens/app/Settings/Parent/Edit'

import ChildIndex from './screens/app/Settings/Child/Index'
import ChildCreate from './screens/app/Settings/Child/Create'
import ChildEdit from './screens/app/Settings/Child/Edit'

import UserIndex from './screens/app/Settings/User/Index'
import UserCreate from './screens/app/Settings/User/Create'
import UserEdit from './screens/app/Settings/User/Edit'

import RoleMYIndex from './screens/app/Role/MY/Index'
import RoleMYCreate from './screens/app/Role/MY/Create'
import RoleMYEdit from './screens/app/Role/MY/Edit'

import RoleMYCRMIndex from './screens/app/Role/MYCRM/Index'
import RoleMYCRMCreate from './screens/app/Role/MYCRM/Create'
import RoleMYCRMEdit from './screens/app/Role/MYCRM/Edit'

import AdviserIndex from './screens/app/Member/Adviser/Index'
import AdviserCreate from './screens/app/Member/Adviser/Create'
import AdviserEdit from './screens/app/Member/Adviser/Edit'

import PrincipalIndex from './screens/app/Member/Principal/Index'
import PrincipalCreate from './screens/app/Member/Principal/Create'
import PrincipalEdit from './screens/app/Member/Principal/Edit'

import AdminIndex from './screens/app/Member/Admin/Index'
import AdminCreate from './screens/app/Member/Admin/Create'
import AdminEdit from './screens/app/Member/Admin/Edit'

import CSMIndex from './screens/app/Member/CSM/Index'
import CSMCreate from './screens/app/Member/CSM/Create'
import CSMEdit from './screens/app/Member/CSM/Edit'

import AdminBranchIndex from './screens/app/Member/AdminBranch/Index'
import AdminBranchCreate from './screens/app/Member/AdminBranch/Create'
import AdminBranchEdit from './screens/app/Member/AdminBranch/Edit'

import OfficeIndex from './screens/app/Office/Index'
import OfficeCreate from './screens/app/Office/Create'
import OfficeEdit from './screens/app/Office/Edit'

import NewsIndex from './screens/app/News/Index'
import NewsCreate from './screens/app/News/Create'
import NewsEdit from './screens/app/News/Edit'

import PartnerIndex from './screens/app/Website/Partner/Index'
import PartnerCreate from './screens/app/Website/Partner/Create'
import PartnerEdit from './screens/app/Website/Partner/Edit'
import TopPerformerIndex from './screens/app/Website/TopPerformer/Index'
import TopPerformerCreate from './screens/app/Website/TopPerformer/Create'
import TopPerformerEdit from './screens/app/Website/TopPerformer/Edit'

import MitraIndex from './screens/app/Mitra/Index'
import MitraCreate from './screens/app/Mitra/Create'
import MitraEdit from './screens/app/Mitra/Edit'

import DeveloperIndex from './screens/app/Developer/Index'
import DeveloperCreate from './screens/app/Developer/Create'
import DeveloperEdit from './screens/app/Developer/Edit'

import DeveloperEmployeeIndex from './screens/app/DeveloperEmployee/Index'
import DeveloperEmployeeCreate from './screens/app/DeveloperEmployee/Create'
import DeveloperEmployeeEdit from './screens/app/DeveloperEmployee/Edit'

import CareerIndex from './screens/app/Career/Index'
import CareerCreate from './screens/app/Career/Create'
import CareerEdit from './screens/app/Career/Edit'
import PromoCategory from './screens/app/PromoCategory'
import Promo from './screens/app/Promo'
import LeadWebsite from './screens/app/LeadWebsite/Index'
import InactiveLead from './screens/app/InactiveLead/Index'

import WhatsappBoardIndex from './screens/app/WhatsappBoard'

import NotAuthenticated from './screens/app/Errors/NotAuthenticated'
import NotFound from './screens/app/Errors/NotFound'


const Routes = () => {
  let location = useLocation()
  let dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route 
        {...rest}
        render = {props => {
          if (userInfo) {
            return <Component {...props}/>
          } else {
            return <Redirect
              to={{
                pathname: "/login",
                state: { from: location.pathname }
              }}
            />
          }
        }}
      />
    );
  }

  return (
    <Switch>
      {/* <Redirect from='/undefined' to='/' /> */}
      
      <Route path='/login' component={Login} />
      <PrivateRoute path='/' component={Dashboard} exact />
      <PrivateRoute path='/dashboard' component={Dashboard} />

      <PrivateRoute path='/parent-index' component={ParentIndex} />
      <PrivateRoute path='/parent-create' component={ParentCreate} />
      <PrivateRoute path='/parent-edit' component={ParentEdit} />
      <PrivateRoute path='/child-index' component={ChildIndex} />
      <PrivateRoute path='/child-create' component={ChildCreate} />
      <PrivateRoute path='/child-edit' component={ChildEdit} />
      <PrivateRoute path='/user-index' component={UserIndex} />
      <PrivateRoute path='/user-create' component={UserCreate} />
      <PrivateRoute path='/user-edit' component={UserEdit} />
      
      <PrivateRoute path='/role-my-index' component={RoleMYIndex} />
      <PrivateRoute path='/role-my-create' component={RoleMYCreate} />
      <PrivateRoute path='/role-my-edit' component={RoleMYEdit} />
      <PrivateRoute path='/role-mycrm-index' component={RoleMYCRMIndex} />
      <PrivateRoute path='/role-mycrm-create' component={RoleMYCRMCreate} />
      <PrivateRoute path='/role-mycrm-edit' component={RoleMYCRMEdit} />

      <PrivateRoute path='/adviser-index' component={AdviserIndex} />
      <PrivateRoute path='/adviser-create' component={AdviserCreate} />
      <PrivateRoute path='/adviser-edit' component={AdviserEdit} />
      <PrivateRoute path='/principal-index' component={PrincipalIndex} />
      <PrivateRoute path='/principal-create' component={PrincipalCreate} />
      <PrivateRoute path='/principal-edit' component={PrincipalEdit} />
      <PrivateRoute path='/admin-index' component={AdminIndex} />
      <PrivateRoute path='/admin-create' component={AdminCreate} />
      <PrivateRoute path='/admin-edit' component={AdminEdit} />
      <PrivateRoute path='/admin-branch-index' component={AdminBranchIndex} />
      <PrivateRoute path='/admin-branch-create' component={AdminBranchCreate} />
      <PrivateRoute path='/admin-branch-edit' component={AdminBranchEdit} />
      <PrivateRoute path='/csm-index' component={CSMIndex} />
      <PrivateRoute path='/csm-create' component={CSMCreate} />
      <PrivateRoute path='/csm-edit' component={CSMEdit} />

      <PrivateRoute path='/office-index' component={OfficeIndex} />
      <PrivateRoute path='/office-create' component={OfficeCreate} />
      <PrivateRoute path='/office-edit' component={OfficeEdit} />

      <PrivateRoute path='/news-index' component={NewsIndex} />
      <PrivateRoute path='/news-create' component={NewsCreate} />
      <PrivateRoute path='/news-edit' component={NewsEdit} />

      <PrivateRoute path='/partner-index' component={PartnerIndex} />
      <PrivateRoute path='/partner-create' component={PartnerCreate} />
      <PrivateRoute path='/partner-edit' component={PartnerEdit} />
      <PrivateRoute path='/top-performer-index' component={TopPerformerIndex} />
      <PrivateRoute path='/top-performer-create' component={TopPerformerCreate} />
      <PrivateRoute path='/top-performer-edit' component={TopPerformerEdit} />

      <PrivateRoute path='/partners-index' component={MitraIndex} />
      <PrivateRoute path='/partners-create' component={MitraCreate} />
      <PrivateRoute path='/partners-edit' component={MitraEdit} />

      <PrivateRoute path='/developer-index' component={DeveloperIndex} />
      <PrivateRoute path='/developer-create' component={DeveloperCreate} />
      <PrivateRoute path='/developer-edit' component={DeveloperEdit} />

      
      <PrivateRoute path='/developer-employee-index' component={DeveloperEmployeeIndex} />
      <PrivateRoute path='/developer-employee-create' component={DeveloperEmployeeCreate} />
      <PrivateRoute path='/developer-employee-edit' component={DeveloperEmployeeEdit} />

      <PrivateRoute path='/career-index' component={CareerIndex} />
      <PrivateRoute path='/career-create' component={CareerCreate} />
      <PrivateRoute path='/career-edit' component={CareerEdit} />

      <PrivateRoute path='/promo-category-index' component={PromoCategory} />
      <PrivateRoute path='/promo-setting-index' component={Promo} />

      <PrivateRoute path='/whatsapp-board-index' component={WhatsappBoardIndex} />

      <PrivateRoute path='/leads-website-index' component={LeadWebsite} />

      <PrivateRoute path='/inactive-leads-index' component={InactiveLead} />
    
      <PrivateRoute path='/403' component={NotAuthenticated} />
      <PrivateRoute path='*' component={NotFound} />
    </Switch>
  )
}

export default Routes
