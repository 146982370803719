import { message } from 'antd'
import { LEAD_WEBSITE_BULK_ACTIVATE_FAIL,LEAD_WEBSITE_BULK_ACTIVATE_REQUEST,LEAD_WEBSITE_BULK_ACTIVATE_SUCCESS,LEAD_WEBSITE_BULK_DELETE_REQUEST,LEAD_WEBSITE_BULK_DELETE_SUCCESS,LEAD_WEBSITE_BULK_DELETE_FAIL,LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL, LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST, LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS, LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL, LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST, LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS, LEAD_WEBSITE_DELETE_FAIL, LEAD_WEBSITE_DELETE_REQUEST, LEAD_WEBSITE_DELETE_SUCCESS, LEAD_WEBSITE_EDIT_FAIL, LEAD_WEBSITE_EDIT_REQUEST, LEAD_WEBSITE_EDIT_SUCCESS, LEAD_WEBSITE_LIST_FAIL, LEAD_WEBSITE_LIST_REQUEST, LEAD_WEBSITE_LIST_SUCCESS, LEAD_WEBSITE_OFFICE_APPLY_FAIL, LEAD_WEBSITE_OFFICE_APPLY_REQUEST, LEAD_WEBSITE_OFFICE_APPLY_SUCCESS, LEAD_WEBSITE_OFFICE_FAIL, LEAD_WEBSITE_OFFICE_REQUEST, LEAD_WEBSITE_OFFICE_SUCCESS, LEAD_WEBSITE_REMARKS_FAIL, LEAD_WEBSITE_REMARKS_REQUEST, LEAD_WEBSITE_REMARKS_SUCCESS, LEAD_WEBSITE_UPDATE_FAIL, LEAD_WEBSITE_UPDATE_REQUEST, LEAD_WEBSITE_UPDATE_STATUS_FAIL , LEAD_WEBSITE_UPDATE_STATUS_REQUEST, LEAD_WEBSITE_UPDATE_STATUS_SUCCESS, LEAD_WEBSITE_UPDATE_SUCCESS, LEAD_WEBSITE_UPDATE_VALIDATION, LEAD_WEBSITE_ACTIVATE_FAIL, LEAD_WEBSITE_ACTIVATE_REQUEST, LEAD_WEBSITE_ACTIVATE_SUCCESS, LEAD_WEBSITE_DEACTIVATE_FAIL, LEAD_WEBSITE_DEACTIVATE_REQUEST, LEAD_WEBSITE_DEACTIVATE_SUCCESS } from '../constants/leadWebsiteConstants';

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  Leadwebsite  ============================================

export const leadWebsiteReducer = (state = {leadWebsite:[], leadWebsites:[], office: []}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_LIST_SUCCESS:
      return{ ...state, loading: false, leadWebsite: action.payload.lists, leadWebsites: action.payload.lists.data.data, office: action.payload.office.data, mitra: action.payload.mitra.data }
    case LEAD_WEBSITE_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteEditStatusReducer = (state = {leadWebsite:{}}, action) => {
  switch (action.type){
    case LEAD_WEBSITE_UPDATE_STATUS_REQUEST : 
    message.loading('Loading...', key);
    return { ...state, loading: true, error: []}
    case LEAD_WEBSITE_UPDATE_STATUS_SUCCESS : 
    message.success({ content: action.payload.message, key });
    return { ...state, loading: false, leadWebsite: action.payload.data }
    case LEAD_WEBSITE_UPDATE_STATUS_FAIL :
    message.error({ content: action.payload, key });
    return { ...state, loading: false, error : action.payload }
    default:
    return state
  }
}

export const leadWebsiteEditReducer = (state = {leadWebsite:{}}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_EDIT_SUCCESS:
      return{ ...state, loading: false, leadWebsite: action.payload.data}
    case LEAD_WEBSITE_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteOfficeReducer = (state = {leadWebsiteOffice : {}}, action) => {
  switch (action.type) { 
    case LEAD_WEBSITE_OFFICE_REQUEST:
      message.loading('Loading', key);
      return { ...state, loading: true, error: [] }
    case LEAD_WEBSITE_OFFICE_SUCCESS:
      message.success({ content: action.payload.message, key });
      return {...state, loading: false, leadWebsiteOffice: action.payload.data}
    case LEAD_WEBSITE_OFFICE_FAIL: 
      message.error({ content: action.payload, key });
      return {...state, loading: false, error: action.payload}
    default: 
    return state
    }
}

export const leadWebsiteUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case LEAD_WEBSITE_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case LEAD_WEBSITE_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case LEAD_WEBSITE_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteApplyOfficeReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_OFFICE_APPLY_REQUEST: 
      message.loading('loading...', key);
      return { ...state, loading: false}
    case LEAD_WEBSITE_OFFICE_APPLY_SUCCESS:
      message.success({ content: action.payload.message, key });
      return { ...state, loading: false}
    case LEAD_WEBSITE_OFFICE_APPLY_FAIL:
      message.error({ content: action.payload, key });
      return { ...state, loading: false, error: action.payload}
      default: 
      return state
  }
}

export const leadWebsiteBulkOfficeApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST:
      message.loading('loading...', key);
      return { ...state, loading: false}
    case LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS:
      message.success({ content: action.payload.message, key});
      return { ...state, loading: false}
    case LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL:
      message.error({ content: action.payload, key});
      return {...state, loading: false, error: action.payload}
    default : 
      return state
  }
}

export const leadWebsiteBulkChangeStatusReducer = (state = {}, action) => {
  switch (action.type){
    case LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST: 
      message.loading('loading...', key);
      return {...state, loading: false}
    case LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS:
      message.success({ content: action.payload.message, key});
      return {...state, loading: false}
    case LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL:
      message.error({ content: action.payload, key});
      return {...state, loading: false, error: action.payload}
    default : 
      return state
  }
}

export const leadWebsiteRemarksReducer = (state = {}, action) => {
  switch (action.type){
    case LEAD_WEBSITE_REMARKS_REQUEST :
      message.loading('loading...', key);
      return {...state, loading: false};
    case LEAD_WEBSITE_REMARKS_SUCCESS :
      message.success({ content: action.payload.message, key});
      return {...state, loading: false};
    case LEAD_WEBSITE_REMARKS_FAIL :
      message.error({ content: action.payload, key});
      return {...state, loading: false, error : action.payload};
    default : 
      return state;
  }
}

export const leadWebsiteDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_DELETE_SUCCESS:
      message.success({ content: action.payload.message, key });
      return{ ...state, loading: false }
    case LEAD_WEBSITE_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteBulkDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_BULK_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_BULK_DELETE_SUCCESS:
      message.success({ content: action.payload.message, key });
      return{ ...state, loading: false }
    case LEAD_WEBSITE_BULK_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_ACTIVATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_ACTIVATE_SUCCESS:
      message.success({ content: action.payload.message, key });
      return{ ...state, loading: false }
    case LEAD_WEBSITE_ACTIVATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteDectivateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_DEACTIVATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_DEACTIVATE_SUCCESS:
      message.success(action.payload.message, key);
      return{ ...state, loading: false }
    case LEAD_WEBSITE_DEACTIVATE_FAIL:
      message.error(action.payload, key);
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const leadWebsiteBulkActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_WEBSITE_BULK_ACTIVATE_REQUEST:
      return{ ...state, loading: true, error: [] }
    case LEAD_WEBSITE_BULK_ACTIVATE_SUCCESS:
      return{ ...state, loading: false }
    case LEAD_WEBSITE_BULK_ACTIVATE_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

