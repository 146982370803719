//=================================  LEAD WEBSITE  ============================================

export const LEAD_WEBSITE_LIST_REQUEST = 'LEAD_WEBSITE_LIST_REQUEST'
export const LEAD_WEBSITE_LIST_SUCCESS = 'LEAD_WEBSITE_LIST_SUCCESS'
export const LEAD_WEBSITE_LIST_FAIL = 'LEAD_WEBSITE_LIST_FAIL'

export const LEAD_WEBSITE_SAVE_REQUEST = 'LEAD_WEBSITE_SAVE_REQUEST'
export const LEAD_WEBSITE_SAVE_SUCCESS = 'LEAD_WEBSITE_SAVE_SUCCESS'
export const LEAD_WEBSITE_SAVE_FAIL = 'LEAD_WEBSITE_SAVE_FAIL'
export const LEAD_WEBSITE_SAVE_VALIDATION = 'LEAD_WEBSITE_SAVE_VALIDATION'

export const LEAD_WEBSITE_EDIT_REQUEST = 'LEAD_WEBSITE_EDIT_REQUEST'
export const LEAD_WEBSITE_EDIT_SUCCESS = 'LEAD_WEBSITE_EDIT_SUCCESS'
export const LEAD_WEBSITE_EDIT_FAIL = 'LEAD_WEBSITE_EDIT_FAIL'

export const LEAD_WEBSITE_REMARKS_REQUEST = 'LEAD_WEBSITE_REMARKS_REQUEST'
export const LEAD_WEBSITE_REMARKS_SUCCESS = 'LEAD_WEBSITE_REMARKS_SUCCESS'
export const LEAD_WEBSITE_REMARKS_FAIL = 'LEAD_WEBSITE_REMARKS_FAIL'

export const LEAD_WEBSITE_UPDATE_REQUEST = 'LEAD_WEBSITE_UPDATE_REQUEST'
export const LEAD_WEBSITE_UPDATE_SUCCESS = 'LEAD_WEBSITE_UPDATE_SUCCESS'
export const LEAD_WEBSITE_UPDATE_FAIL = 'LEAD_WEBSITE_UPDATE_FAIL'
export const LEAD_WEBSITE_UPDATE_VALIDATION = 'LEAD_WEBSITE_UPDATE_VALIDATION'

export const LEAD_WEBSITE_OFFICE_REQUEST = 'LEAD_WEBSITE_OFFICE_REQUEST'
export const LEAD_WEBSITE_OFFICE_SUCCESS = 'LEAD_WEBSITE_OFFICE_SUCCESS'
export const LEAD_WEBSITE_OFFICE_FAIL = 'LEAD_WEBSITE_OFFICE_FAIL'

export const LEAD_WEBSITE_OFFICE_EDIT_REQUEST = 'LEAD_WEBSITE_OFFICE_EDIT_REQUEST'
export const LEAD_WEBSITE_OFFICE_EDIT_SUCCESS = 'LEAD_WEBSITE_OFFICE_EDIT_SUCCESS'
export const LEAD_WEBSITE_OFFICE_EDIT_FAIL = 'LEAD_WEBSITE_OFFICE_EDIT_FAIL'

export const LEAD_WEBSITE_OFFICE_APPLY_REQUEST = 'LEAD_WEBSITE_OFFICE_APPLY_REQUEST'
export const LEAD_WEBSITE_OFFICE_APPLY_FAIL = 'LEAD_WEBSITE_OFFICE_APPLY_FAIL'
export const LEAD_WEBSITE_OFFICE_APPLY_SUCCESS = 'LEAD_WEBSITE_OFFICE_APPLY_SUCCESS'

export const LEAD_WEBSITE_UPDATE_STATUS_REQUEST = 'LEAD_WEBSITE_UPDATE_STATUS_REQUEST'
export const LEAD_WEBSITE_UPDATE_STATUS_SUCCESS = 'LEAD_WEBSITE_UPDATE_STATUS_SUCCESS'
export const LEAD_WEBSITE_UPDATE_STATUS_FAIL = 'LEAD_WEBSITE_UPDATE_STATUS_FAIL'

export const LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST ='LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST'
export const LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS = 'LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS'
export const LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL = 'LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL'

export const LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST = 'LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST'
export const LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS ='LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS'
export const LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL ='LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL'

export const LEAD_WEBSITE_DELETE_REQUEST = 'LEAD_WEBSITE_DELETE_REQUEST'
export const LEAD_WEBSITE_DELETE_SUCCESS = 'LEAD_WEBSITE_DELETE_SUCCESS'
export const LEAD_WEBSITE_DELETE_FAIL = 'LEAD_WEBSITE_DELETE_FAIL'

export const LEAD_WEBSITE_BULK_DELETE_REQUEST = 'LEAD_WEBSITE_BULK_DELETE_REQUEST'
export const LEAD_WEBSITE_BULK_DELETE_SUCCESS = 'LEAD_WEBSITE_BULK_DELETE_SUCCESS'
export const LEAD_WEBSITE_BULK_DELETE_FAIL = 'LEAD_WEBSITE_BULK_DELETE_FAIL'

export const LEAD_WEBSITE_DEACTIVATE_REQUEST = 'LEAD_WEBSITE_BULK_DELETE_REQUEST'
export const LEAD_WEBSITE_DEACTIVATE_SUCCESS = 'LEAD_WEBSITE_DEACTIVATE_SUCCESS'
export const LEAD_WEBSITE_DEACTIVATE_FAIL = 'LEAD_WEBSITE_DEACTIVATE_FAIL'

export const LEAD_WEBSITE_ACTIVATE_REQUEST = 'LEAD_WEBSITE_ACTIVATE_REQUEST'
export const LEAD_WEBSITE_ACTIVATE_SUCCESS = 'LEAD_WEBSITE_ACTIVATE_SUCCESS'
export const LEAD_WEBSITE_ACTIVATE_FAIL = 'LEAD_WEBSITE_ACTIVATE_FAIL'

export const LEAD_WEBSITE_BULK_ACTIVATE_REQUEST = 'LEAD_WEBSITE_BULK_ACTIVATE_REQUEST'
export const LEAD_WEBSITE_BULK_ACTIVATE_SUCCESS = 'LEAD_WEBSITE_BULK_ACTIVATE_SUCCESS'
export const LEAD_WEBSITE_BULK_ACTIVATE_FAIL = 'LEAD_WEBSITE_BULK_ACTIVATE_FAIL'