import {
    MESSAGE_REQUEST,
    MESSAGE_GET_SUCCESS,
    MESSAGE_FAIL,
    MESSAGE_GET_NEXT_SUCCESS,
    MESSAGE_ADD_SUCCESS,
    MESSAGE_UNREAD_ADD,
    MESSAGE_UNREAD_REMOVE,
    MESSAGE_CLEAR,
    MESSAGE_DELETE
} from '../constants/whatsappConstants'

const initialState = {
    messages: [],
    loading: false,
    next: null,
    newMessages: []
}

export const whatappReducer = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_REQUEST:
            return {...state, loading: true}
        case MESSAGE_GET_SUCCESS:
            return {...state, loading: false, messages: action.payload, next: action.next}
        case MESSAGE_GET_NEXT_SUCCESS:
            return {...state, loading: false, messages: [...action.payload, ...state.messages], next: action.next}
        case MESSAGE_ADD_SUCCESS:
            return {...state, loading: false, messages: [...state.messages, action.payload]}
        case MESSAGE_UNREAD_ADD:
            return {...state, newMessages: [...state.newMessages, action.newMessage]}
        case MESSAGE_UNREAD_REMOVE:
            return {
                ...state,
                newMessages: state.newMessages.filter(message => message !== action.phoneNum)
            }
        case MESSAGE_FAIL:
            return {...state, loading: false, error: action.payload}
        case MESSAGE_CLEAR:
            return {...state, messages: [], next: null}
        case MESSAGE_DELETE:
            return {
                ...state,
                loading: false,
                messages: state.messages.filter(message => message.id !== action.id)
            }
        default: 
            return state
    }
}