import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  leadWebsiteDelete,
  leadWebsiteList,
  leadWebsiteUpdateStatus,
  leadWebsiteOfficeEdit,
  leadWebsiteBulkOfficeApply,
  leadWebsiteBulkChangeStatus,
  leadWebsiteRemarks,
  leadWebsiteBulkDelete,
  leadWebsiteUpdate,
  leadWebsiteActivate,
  leadWebsiteBulkActivate
} from "../../../store/actions/leadWebsiteActions";
import { provinceList, citybyprovinceList} from '../../../store/actions/lookupActions'
import { mitraList } from "../../../store/actions/mitraActions";
import {
  message,
  Popconfirm,
  Button,
  Popover,
  Modal,
  Input,
  Grid,
  DatePicker,
  InputNumber
} from "antd";
import {
  convertNumberPhone,
  IDRFormat,
  MaskEmail,
  MaskPhoneNumber,
} from "../../../utils/helper";
import DeleteConfirm from "../../../components/DeleteConfirm";
import TableWrapper from "../../../components/Tables";
import ModalGeneric from "../../../components/Modals";
import Pagination from "../../../components/Pagination";
import TextArea from "antd/lib/input/TextArea";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useFilter } from "../../../hooks/useFilter";
import WhatsappDrawer from "../../../components/Drawers/WhatsappDrawer";
import { Badge, Tooltip, Select } from "antd";
import moment from "moment";

const FormDetails = (props) => {
  const {modalDetail} = props
  
  const { listsProvince } = useSelector(state => state.provinceList)
  const { listsCity } = useSelector(state => state.citybyprovinceList)

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [tenor, setTenor] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [referenceUrl, setReferenceUrl] = useState("");
  const [note, setNote] = useState("");
  const [remarks, setRemarks] = useState("");

  let history = useHistory();

  useEffect(() => {
    setName(modalDetail?.data?.name);
    setEmail(modalDetail?.data?.email);
    setPhone(modalDetail?.data?.phone);
    setCreatedAt(modalDetail?.data?.created_at);
    setUpdatedAt(modalDetail?.data?.updated_at);
    setUpdatedBy(JSON.parse(localStorage.getItem("userInfo")).user.name);
    setPropertyPrice(modalDetail?.data?.property_price);
    setLoanAmount(modalDetail?.data?.loan_amount);
    setDownPayment(modalDetail?.data?.down_payment);
    setTenor(modalDetail?.data?.tenor);
    setCity(modalDetail?.data?.city);
    setProvince(modalDetail?.data?.province);
    setDistrict(modalDetail?.data?.district);
    setReferenceUrl(modalDetail?.data?.reference_url);
    setNote(modalDetail?.data?.note);
    setRemarks(modalDetail?.data?.remarks);
  }, [modalDetail]);

  let dispatch = useDispatch()

  useEffect(() => {
      dispatch(provinceList())
      dispatch(mitraList())
  }, [dispatch])

  const handleChangeProvince = (e) => {
    const value = JSON.parse(e)
    setProvince(value.name)
    dispatch(citybyprovinceList(value.id))
  }

  const handleUpdate = () => {
    const updateData = {
      name,
      email,
      phone,
      createdAt,
      updatedAt,
      updatedBy,
      propertyPrice,
      loanAmount,
      downPayment,
      tenor,
      city,
      province,
      district,
      referenceUrl,
      note,
      remarks,
    }

    dispatch(leadWebsiteUpdate(updateData, modalDetail?.data?.id, history))
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        height: "100%",
      }}

      key={modalDetail?.data?.id}
    >
      <div style={{ width: "100%" }}>
        <label for="office">Office</label>
        <Input
          style={{ textAlign: "center", color: 'gray'}}
          value={
            modalDetail?.data?.office ? modalDetail?.data?.office : "-"
          }
          disabled
        />
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="name">Name</label>
          <Input defaultValue={modalDetail?.data?.name} style={{ color: 'gray'}} onChange={(value) => setName(value.target.value)}/>
        </div>
        <div style={{ width: '100%'}}>
          <label for="mitra">Mitra</label>
          <Input
              value={modalDetail?.data?.mitra}
              style={{ color: 'gray'}}
              disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="email">Email</label>
          <Input style={{ color: 'gray'}} defaultValue={modalDetail?.data?.email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={{ width: '100%'}}>
          <label for="phone">Phone</label>
          <Input style={{ color: 'gray'}} defaultValue={modalDetail?.data?.phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <label for="created_at">Created At</label>
          <DatePicker 
            style={{ color: 'gray', display: 'block'}}
            onChange={(date, dateString) => setCreatedAt(dateString)} 
            format="DD MMMM YYYY"
            defaultValue={moment(modalDetail?.data?.created_at)}
            disabled
          />

        </div>
        <div style={{ width: "100%" }}>
          <label for="updated_at">Updated At</label>
          <DatePicker 
            style={{ color: 'gray', display: 'block'}}
            onChange={(date, dateString) => setUpdatedAt(dateString)} 
            format="DD MMMM YYYY"
            defaultValue={moment(modalDetail?.data?.updated_at)}
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Updated By</label>
          <Input style={{ color: 'gray'}} defaultValue={JSON.parse(localStorage.getItem("userInfo")).user.name} onChange={(e) => setUpdatedBy(e.target.value)}/>
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Property Price</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%",}}
            defaultValue={Number(modalDetail?.data?.property_price)}
            onChange={(e) => setPropertyPrice(e)}
            formatter={(value) => IDRFormat(value)}
            parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Loan Amount</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%",}}
            defaultValue={Number(modalDetail?.data?.loan_amount)}
            onChange={(e) => setLoanAmount(e)}
            formatter={(value) => IDRFormat(value)}
            parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")}
          />
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Down Payment</label>
            <InputNumber
              style={{ color: 'gray', display: "block", width: "100%"}}
              defaultValue={Number(modalDetail?.data?.down_payment)}
              onChange={(e) => setDownPayment(e)}
              formatter={(value) => IDRFormat(value)}
              parser={(value) => (value ? value.replace(/[^0-9]/g, "") : "")} // Removes 'Rp' and ',' on input
            />
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Tenor (tahun)</label>
          <InputNumber
            style={{ color: 'gray', display: "block", width: "100%"}}
            defaultValue={modalDetail?.data?.tenor}
            onChange={(e) => setTenor(e)}
          />
        </div>
      </div>
      <div style={{width: "100%", border: "1px solid lightgray"}}>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label for="updated_by">Province</label>
           <Select 
              defaultValue={modalDetail?.data?.province}
              style={{ color: 'gray', display: 'block'}}
              onChange={(e) => handleChangeProvince(e)}
            >
              {
                listsProvince.map((item, index) => {
                  return(
                    <Select.Option value={JSON.stringify({id: item.id, name: item.name})} key={index}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
        </div>
        <div style={{ width: '100%'}}>
          <label for="updated_by">city</label>
          <Select
            style={{ color: 'gray', display: "block"}}
            defaultValue={modalDetail?.data?.city}
            onChange={(e) => setCity(e)}
          >
              {
                listsCity.map((item, index) => {
                  return(
                    <Select.Option value={item.name} key={index}>{item.name}</Select.Option>
                  )
                })
              }

          </Select>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        <div style={{ width: '100%'}}>
          <label>district</label>
          <Input 
            defaultValue={modalDetail?.data?.district} 
            style={{ color: 'gray'}}  
            onChange={(e) => setDistrict(e.target.value)}
          />
        </div>
        <div style={{ width: '100%'}}>
          <label>Reference URL</label>
          <Input 
            style={{ color: 'gray'}} 
            defaultValue={modalDetail?.data?.reference_url}  
            onChange={(e) => setReferenceUrl(e.target.value)}
          />
        </div>
      </div>
      <div>
        <label for="remarks">Note</label>
        <TextArea
          style={{ color: 'gray'}}
          defaultValue={
            modalDetail?.data?.note ? modalDetail?.data?.note : ""
          }
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <div>
        <label for="remarks">Remarks</label>
        <TextArea
        key={modalDetail?.data?.remarks}
        style={{ color: 'gray'}}
          defaultValue={
            modalDetail?.data?.remarks ? modalDetail?.data?.remarks : ""
          }
          onChange={(e) => setRemarks(e.target.value)}
        />
      </div>

      <button className="btn btn-sm btn-info" onClick={handleUpdate}>Update</button>
    </div>
  )
};

const InactiveLead = () => {
  const [whatsAppDrawer, setWhatsappDrawer] = useState(false);
  const whatsappDrawerNumber = useRef(0)
  const chatName = useRef("");
  const {getQueryParam, resetQueryParams} = useQueryParams();
  let dispatch = useDispatch();
  const queryStatus = getQueryParam("status");
  const queryOffice = getQueryParam("office_id");
  const queryMitra = getQueryParam("mitra_id");
  const [search, setsearch] = useState("");
  const [isOffice, setIsOffice] = useState("");
  const [status, setStatus] = useState("0");
  const [isModalEdit, setIsModalEdit] = useState({
    shown: false,
    id: "",
  });
  const [isModalApply, setIsModalApply] = useState({
    shown: false,
    id: "",
  });
  const [isModalApplyBulk, setIsModalApplyBulk] = useState({
    shown: false,
    id: [],
  });
  const [isModalChangeStatusBulk, setIsModalChangeStatusBulk] = useState({
    shown: false,
    data: [],
  });
  const [modalRemarks, setModalRemarks] = useState({
    shown: false,
    id: "",
    content: "",
  });
  const [modalDetail, setModalDetail] = useState({
    shown: false,
    data: {},
  });
  const dataleadWebsiteList = useSelector((state) => state.leadWebsite);
  const { leadWebsite, leadWebsites, office, mitra } = dataleadWebsiteList;
  const [valueRemarks, setValueRemarks] = useState("");
  const {leadWebsiteFilter} = useFilter();
  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const [isChecked, setIsChecked] = useState([]);
  const isCheckedAll = isChecked.length === leadWebsites.length;

  const {newMessages} = useSelector((state) => state.whatsapp);
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();

  const isStatus = [
    {
      id: 0,
      name: "Cold",
      color: "#ef4444",
    },
    {
      id: 1,
      name: "Warm",
      color: "#f59e0b",
    },
    {
      id: 2,
      name: "Hot",
      color: "#22c55e",
    },
  ];

  const handleRemarks = (id) => {
    setModalRemarks({
      shown: true,
      id: id,
      content: leadWebsites?.find((item) => item.id === id)?.remarks,
    });
  };

  const tableHead = [
    { name: "All", checkbox: true },
    { name: "Mitra" },
    { name: "Name" },
    { name: "Email" },
    { name: "Phone" },
    { name: "Remarks" },
    { name: "Whatsapp"},
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
      {
        component: (item) => (
          <input
            type="checkbox"
            className="form-check-input p-0 m-0 position-static"
            onChange={() => handleChecked(item)}
            checked={isChecked?.includes(item)}
          />
        ),
        align: "center",
      },
      { render: (item) => item.mitra },
      { render: (item) => item.name },
      { render: (item) => <MaskEmail item={item} /> },
      { render: (item) => {
          item.phone = convertNumberPhone(item.phone)
          return <MaskPhoneNumber item={item} />
        } 
      },
      {
        render: (item) => (
          <div
            align="center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Tooltip title={item?.remarks ? item?.remarks : "-"}>
              <p>{item?.remarks ? (item?.remarks?.length >= 10 ? item?.remarks?.slice(0, 10) + "..." : item?.remarks) : "-"}</p>
            </Tooltip>
              <i
                className="fa fa-edit text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => handleRemarks(item?.id)}
              ></i>
          </div>
        ),
      },
      {
        render: (item) => {    
          item.phone = convertNumberPhone(item.phone)
          return (  
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Badge 
                count={newMessages.filter(message => message === item.phone).length} 
                size="small" 
                style={{ color: "orangered", fontSize: "1px" }}
              >
                <i 
                  className="fa fa-whatsapp text-success text-center" 
                  style={{ fontSize: "25px" }} 
                  onClick={() => { 
                    whatsappDrawerNumber.current = convertNumberPhone(item.phone); 
                    chatName.current = item.name
                    setWhatsappDrawer(true);
                  }
                } 
                  aria-hidden="true"
                ></i>
              </Badge>
            </div>

          )
        }
      },
      {
        render: (item) => (
          <button
            style={{
              backgroundColor: isStatus.find((e) => e.id === item.status)?.color,
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              border: "none"
            }}
            onClick={() => setIsModalEdit({ ...isModalEdit, id: item.id, shown: true })}
            align="center"
          >
            {isStatus.find((e) => e.id === item.status)?.name}
          </button>
        ),
      },
      {
        render: (item) => (
          <div style={{ display: "flex", gap: "", alignItems: 'center' }}>
            <div style={{ display: "flex" }}>
              {userInfo.permission.find((e) => e === "leads-website-delete") ? (
                <DeleteConfirm
                  title={`Are you sure to delete ${item.name}`}
                  onConfirm={() => handleDelete(item.id)}
                  onCancel={() => message.info("Canceled!")}
                />
              ) : null}
              <button type="button" className="btn btn-link mx-0" onClick={() => showDetail(item)}>
                <i className="fa fa-eye" aria-hidden="true"/>
              </button>
              <Popconfirm
                title="Activate this lead?"
                onConfirm={() => handleActivate(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className="btn btn-link mx-0">
                  <i className="fa fa-archive text-warning" aria-hidden="true"/>
                </button>
              </Popconfirm>
            </div>
          </div>
        ),
      },
  ]

  useEffect(() => {
    dispatch(leadWebsiteList(false, 1, search, queryStatus, queryOffice, queryMitra));
  }, [dispatch, queryMitra, queryOffice, queryStatus, search]);

  useEffect(() => {
    if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
    if (!userInfo.permission.includes("leads-website-read")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(leadWebsiteList(false, 1, search));
  };


  const resetFilter = () => {
    resetQueryParams();
    return dispatch(leadWebsiteList(false, 1, search));
  };

  const handleCheckedAll = () => {
    if (isCheckedAll) {
      setIsChecked([]);
    } else {
      setIsChecked(leadWebsites);
    }
  };

  const handleChecked = (data) => {
    setIsChecked((prev) => {
      if (prev.includes(data)) {
        return prev.filter((id) => id !== data);
      } else {
        return [...prev, data];
      }
    });
  };

  // Handling API Actions
  const handleDelete = (id) => {
    dispatch(leadWebsiteDelete(id)).then(() => {
      dispatch(leadWebsiteList(false, 1, search));
    });
  };

  const handleBulkDelete = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkDelete(bulkId)).then(() => {
      dispatch(leadWebsiteList(false, 1, search)).then(() => {
        setIsChecked([]);
      });
    });
  };

  const handleBulkActivate = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkActivate(bulkId, true)).then(() => {
      dispatch(leadWebsiteList(false, 1, search)).then(() => {
        setIsChecked([]);
      });
    });
  };

  const handleActivate = (id) => {
    dispatch(leadWebsiteActivate(id)).then(() => {
      dispatch(leadWebsiteList(false, 1, search))
    })
  }

  const handleOkStatus = () => {
    const id = isModalEdit.id;
    dispatch(leadWebsiteUpdateStatus(id, status?.toString()));
    setTimeout(() => {
      setIsModalEdit({
        id: "",
        shown: false,
      });
      dispatch(leadWebsiteList(false, 1, search));
    }, 1000);
  };

  const handleCancelStatus = () => {
    setIsModalEdit({
      ...isModalEdit,
      shown: false,
    });
  };

  const handleOkOffice = () => {
    dispatch(leadWebsiteOfficeEdit(isModalApply.id, isOffice)).then((res) => {
      setIsModalApply({
        id: "",
        shown: false,
      });
      dispatch(leadWebsiteList(false, 1, search));
    });
  };

  const handleCancelOffice = () => {
    setIsModalApply({
      shown: false,
      id: "",
    });
  };

  const handleApplyOfficeBulking = () => {
    if (isChecked.length > 0) {
      setIsModalApplyBulk({
        shown: true,
        data: isChecked,
      });
    }
  };

  const handleChangeStatusBulking = () => {
    if (isChecked.length > 0) {
      setIsModalChangeStatusBulk({
        shown: true,
        data: isChecked,
      });
    }
  };

  const handleOkChangeStatusBulk = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkChangeStatus(bulkId, status?.toString())).then(() => {
      setTimeout(() => {
        setIsModalChangeStatusBulk({
          data: "",
          shown: false,
        });
        setIsChecked([]);
        dispatch(leadWebsiteList(false, 1, search));
      }, 1000);
    });
  };

  const handleOkOfficeBulk = () => {
    const bulkId = isChecked?.map((item) => item.id);
    dispatch(leadWebsiteBulkOfficeApply(bulkId, isOffice)).then((res) => {
      setTimeout(() => {
        setIsModalApplyBulk({
          data: "",
          shown: false,
        });
        setIsChecked([]);
        dispatch(leadWebsiteList(false, 1, search));
      }, 1000);
    });
  };

  const handleOkRemarks = (remarksId) => {
    if (remarksId === undefined) {
      remarksId = modalRemarks.id;
    }

    const data = valueRemarks
    setValueRemarks("");
    dispatch(leadWebsiteRemarks(remarksId, data)).then((res) => {
      setTimeout(() => {
        setModalRemarks({
          id: "",
          shown: false,
        });
        dispatch(leadWebsiteList(false, 1, search));
      }, 1000);
    });
  };

  const showDetail = (item) => {
    setModalDetail({
      shown: true,
      data: item,
    });
  };

  return (
    <div className="">
      <WhatsappDrawer whatsAppDrawer={whatsAppDrawer} setWhatsappDrawer={setWhatsappDrawer} phoneNumber={whatsappDrawerNumber.current} chatName={chatName.current}/>
      {/* Modal Edit Status */}
      <ModalGeneric
        title="Change Status"
        contentType="single"
        visible={isModalEdit?.shown}
        onOk={handleOkStatus}
        onCancel={handleCancelStatus}
        options={isStatus}
        onChange={(e) => setStatus(e?.target?.value)}
      />

      {/* Modal Edit Apply Office */}
      <ModalGeneric
        title="Apply Office to CRM"
        contentType="single"
        visible={isModalApply?.shown}
        onOk={handleOkOffice}
        onCancel={handleCancelOffice}
        options={office}
        onChange={(e) => setIsOffice(e?.target?.value)}
      />

      {/* Modal Edit Bulk Apply Office */}
      <ModalGeneric
        title="Apply Office to CRM"
        contentType="bulk"
        visible={isModalApplyBulk?.shown}
        onOk={handleOkOfficeBulk}
        onCancel={() =>
          setIsModalApplyBulk({ ...isModalApplyBulk, shown: false })
        }
        options={office}
        onChange={(e) => setIsOffice(e?.target?.value)}
        datas={leadWebsites}
        filter={isModalApplyBulk?.data}
      />

      {/* Modal Change Status Bulk */}
      <ModalGeneric
        title="Edit Status"
        contentType="bulk"
        visible={isModalChangeStatusBulk?.shown}
        onOk={handleOkChangeStatusBulk}
        onCancel={() =>
          setIsModalChangeStatusBulk({
            ...isModalChangeStatusBulk,
            shown: false,
          })
        }
        options={isStatus}
        datas={leadWebsites}
        filter={isModalChangeStatusBulk?.data}
        onChange={(e) => setStatus(e?.target?.value)}
      />

      {/* Modal Remarks update */}
      <Modal
        title="Remarks"
        open={modalRemarks.shown}
        onOk={() => handleOkRemarks()}
        onCancel={() => setModalRemarks({ ...modalRemarks, shown: false, content: "" })}
      >
        <TextArea
          key={modalRemarks.id}
          defaultValue={modalRemarks.content}
          onChange={(e) => setValueRemarks(e.target.value)}
          placeholder="Add Remarks here.."
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={50}
        />
        <div
          style={{
            marginTop: 8,
            textAlign: "right",
            fontSize: 12,
            color: valueRemarks.length >= 50 ? "red" : "gray",
          }}
        >
          {`${valueRemarks.length} / 50 characters`}
        </div>
      </Modal>

      {/* Modal Detail's  */}
      <Modal
        title="Detail Information"
        onCancel={() => setModalDetail({ ...modalDetail, shown: false })}
        open={modalDetail.shown}
        style={{ top: 20 }}
        footer={
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{ width: "100%", backgroundColor: "#1aa6ed" }}
              onClick={() => setModalDetail({ ...modalDetail, shown: false })}
              htmlType="button"
            >
              <span style={{ color: "white" }}>Close</span>
            </Button>
          </div>
        }
      >
        <FormDetails modalDetail={modalDetail}/>
      </Modal>

      <div className="page-title">
        <div className="title_left">
          <h3>Inactive Leads</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div style={{ display: "flex",alignItems: "center", justifyContent: "space-between", flexDirection: md ? "row" : "column", gap: 10 }}>
                <div style={{ width: "100%", display: md ? "none" : "flex", position: "relative" }}>
                  <input
                        type="text"
                        className="form-control"
                        placeholder="Search By Name"
                        style={{ float: "right", width: "100%" }}
                        onChange={(e) => setsearch(e.target.value)}
                      />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      margin: "auto",
                      textDecoration: "none", 
                      border: "none",
                      position: "absolute",
                      right: 0
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
                
                <div className="col-md-6 col-sm-12 col-xs-12" style={{ display: md ? "flex" : "none", alignItems: "center"}}>
                  {isChecked?.length > 0 ? (
                    <div className="d-flex align-items-center m-0 text-nowrap flex-wrap">
                      <p className="m-0 mr-2 font-weight-bold" style={{fontSize: "11px", color: "#1aa6ed"}}>
                        {isChecked?.length} Selected of{" "}
                        {leadWebsites.length ? leadWebsites.length : 0}
                      </p>
                      <button
                        className="btn"
                        style={{fontSize: "11px",  backgroundColor: '#1aa6ed', color: 'white'}}
                        onClick={(e) => handleApplyOfficeBulking(e)}
                      >
                        Send to CRM
                      </button>
                      <button
                        className="btn"
                        style={{fontSize: "11px",  backgroundColor: '#1aa6ed', color: 'white'}}
                        onClick={(e) => handleChangeStatusBulking(e)}
                      >
                        Status
                      </button>
                      <button
                        className="btn"
                        style={{fontSize: "11px",  backgroundColor: '#1aa6ed', color: 'white'}}
                        onClick={() => handleBulkDelete()}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <button
                        className="btn"
                        style={{fontSize: "11px",  backgroundColor: '#1aa6ed', color: 'white'}}
                        onClick={() => handleBulkActivate()}
                      >
                        <i className="fa fa-share-square"></i>
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback d-flex gap-2 justify-content-end">
                  <div
                    className="d-flex align-items-center justify-content-end mr-2"
                    style={{ width: md ? "50%" : "100%" }}
                  >
                    <Button
                      type="button"
                      className="btn btn-primary m-0"
                      onClick={() => resetFilter()}
                    >
                      <i style={{ fontSize: md? "14px" : "12px" }} className="fa fa-refresh mr-1" />
                    </Button>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div className="d-flex flex-column">
                          {isStatus.map((item, key) => (
                            <button
                              type="button"
                              className="btn btn-link text-left"
                              onClick={() =>
                                leadWebsiteFilter(search, item.id?.toString(), null, null)
                              }
                              value={item.id}
                              key={key}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Status"
                      trigger="click"
                    >
                      <Button style={{ fontSize: md? "14px" : "12px" }}>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Status{" "}
                          {":" + (queryStatus ? " " + queryStatus : " All")}
                        </span>
                      </Button>
                    </Popover>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div
                          className="d-flex flex-column overflow-auto"
                          style={{ maxHeight: "240px" }}
                        >
                          {office?.map((item, key) => (
                            <button
                              type="button"
                              className="btn btn-link text-nowrap text-left"
                              onClick={() => leadWebsiteFilter(search, null, item.id, null)}
                              value={item.id}
                              key={key}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Office"
                      trigger="click"
                    >
                      <Button style={{ fontSize: md? "14px" : "12px" }}>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Office{" "}
                          {":" + (queryOffice ? " " + queryOffice : " All")}
                        </span>
                      </Button>
                    </Popover>

                    <Popover
                      placement="bottom"
                      overlayClassName="popover-filter"
                      content={
                        <div className="d-flex flex-column">
                          {mitra?.map((item, key) => (
                            <button
                              type="button"
                              className="btn btn-link text-left"
                              onClick={() => leadWebsiteFilter(search, null, null, item.id)}
                              value={item.id}
                              key={key}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      }
                      title="Office"
                      trigger="click"
                    >
                      <Button style={{ fontSize: md? "14px" : "12px" }}>
                        <i className="fa fa-filter" />
                        <span className=" ml-1">
                          Mitra {":" + (queryMitra ? " " + queryMitra : " All")}
                        </span>
                      </Button>
                    </Popover>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%", display : !md ? 'none' : 'block' }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                      display : !md ? 'none' : 'block' 
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>

                <div className="col-md-6 col-sm-12 col-xs-12" style={{ display: !md ? "flex" : "none", alignItems: "center", marginTop: 0, marginBottom: 10}}>
                  {isChecked?.length > 0 ? (
                    <div style={{  display: 'flex', alignItems: 'center'}}>
                        <p style={{ fontSize: 12, margin: 0, paddingRight: 10, color: "#1aa6ed" }}>
                          {isChecked?.length} Selected of{" "}
                          {leadWebsites.length ? leadWebsites.length : 0}
                        </p>
                        <div style={{ display: 'flex', gap: 10 }}>
                          <button
                          style={{padding: '5px 10px', fontSize: 12, backgroundColor: '#1aa6ed', color: 'white', borderRadius: 5, border: 'none', margin: 0}}
                            className="btn btn-primary"
                            onClick={(e) => handleApplyOfficeBulking(e)}
                          >
                            Send to CRM
                          </button>
                          <button
                          style={{padding: '5px 10px', fontSize: 12, backgroundColor: '#1aa6ed', color: 'white', borderRadius: 5, border: 'none', margin: 0}}
                            onClick={(e) => handleChangeStatusBulking(e)}
                          >
                            Change Status
                          </button>
                          <button
                          style={{padding: '5px 10px', fontSize: 12, backgroundColor: '#1aa6ed', color: 'white', borderRadius: 5, border: 'none', margin: 0}}
                          onClick={() => handleBulkDelete()}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <button
                            className="btn"
                            style={{padding: '5px 10px', fontSize: 12, backgroundColor: '#1aa6ed', color: 'white', borderRadius: 5, border: 'none', margin: 0}}
                            onClick={() => handleBulkActivate()}
                          >
                            <i className="fa fa-share-square"></i>
                          </button>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <TableWrapper
                onRowClick={showDetail}
                tableHead={tableHead}
                tableBody={tableBody}
                datas={leadWebsites}
                isStatus={isStatus}
                handleDelete={handleDelete}
                handleCheckedAll={handleCheckedAll}
                isCheckedAll={isCheckedAll}
              />
              <Pagination
                listsData={leadWebsiteList}
                datas={leadWebsite}
                search={search}
                active={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InactiveLead;
